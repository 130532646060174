import React, { FC, ReactNode, useMemo } from 'react';
import {
  IconBicycle,
  IconCalendar,
  IconCutlery,
  IconEvCharge,
  IconFireFlame,
  IconGamepad,
  IconGym,
  IconHealthcare,
  IconHomeTable,
  IconParking,
  IconStar,
  IconWarningTriangle,
} from '@allbin/icons';
import { Service } from '../../types/service';
import { MapMarker } from '../MapMarker';
import { useFeatures } from '../../hooks/useFeatures';

interface Props {
  service: Service;
  onClick?: () => void;
  hideText?: boolean;
}

const MapServiceMarker: FC<Props> = ({ service, onClick, hideText }) => {
  const { data: featureCollection } = useFeatures();

  const feature = useMemo(
    () =>
      featureCollection
        ? featureCollection.features.find(
            (f) => f.properties?.service_id === service.id,
          )
        : undefined,
    [featureCollection, service.id],
  );

  if (!feature) {
    return null;
  }

  // FIXME: right now, all of these are just points. in the future they might be polygons, so we should handle that
  const coordinate = (feature.geometry as { coordinates: number[] })[
    'coordinates'
  ];

  return (
    <MapMarker
      icon={getIcon(service)}
      label={service.name}
      latitude={coordinate[1]}
      longitude={coordinate[0]}
      hideText={hideText}
      onClick={onClick}
    />
  );
};

const getIcon = (service: Service): ReactNode => {
  switch (service.type) {
    case 'charging_station':
      return <IconEvCharge />;
    case 'bicycles':
      return <IconBicycle />;
    case 'fireplace':
      return <IconFireFlame />;
    case 'gym':
      return <IconGym />;
    case 'relax':
      return <IconHealthcare />;
    case 'admin':
      return <IconHomeTable />;
    case 'game':
      return <IconGamepad />;
    case 'restaurant':
      return <IconCutlery />;
    case 'parking':
      return <IconParking />;
    case 'conference_room':
      return <IconCalendar />;
    case 'other':
      return <IconStar />;

    default:
      return <IconWarningTriangle />;
  }
};

export default MapServiceMarker;
