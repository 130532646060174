import { ApiFloor, ApiFloorRequest } from '@allbin/viu-api-client';
import { Floor, FloorRequest, reviveFloor } from '../types/floor';

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import { useApiClient } from './useApiClient';
import { useIntl } from 'react-intl';
import { useIdentity } from '../providers/IdentityProvider';

export const serializeFloorRequest = (
  floor: FloorRequest,
): ApiFloorRequest => ({
  ...floor,
});

export const useFloors = (): UseQueryResult<Floor[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['floors', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices
        .getFloors(id ?? '')
        .then((floors) => floors.map((floor) => reviveFloor(floor))),
    enabled: true,
  });

  return query;
};

interface UseFloorMutations {
  create: UseMutationResult<Floor, Error, FloorRequest>;
  update: UseMutationResult<Floor, Error, Pick<ApiFloor, 'id'> & FloorRequest>;
  remove: UseMutationResult<Floor, Error, Pick<Floor, 'id'>>;
}

export const useFloorMutations = (): UseFloorMutations => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return {
    create: useMutation<Floor, Error, FloorRequest, unknown>({
      mutationFn: (floor) =>
        apiClient.floors
          .create(serializeFloorRequest(floor))
          .then(reviveFloor)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte skapa våningsplan',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['floors', id],
        }),
    }),
    update: useMutation<Floor, Error, Pick<Floor, 'id'> & FloorRequest>({
      mutationFn: ({ id, ...floor }) =>
        apiClient.floors
          .update(id, serializeFloorRequest(floor))
          .then(reviveFloor)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte uppdatera våningsplan',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['floors', id],
        }),
    }),
    remove: useMutation<Floor, Error, Pick<Floor, 'id'>, unknown>({
      mutationFn: ({ id }) =>
        apiClient.floors
          .delete(id)
          .then(reviveFloor)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte ta bort lägenhet',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['locations', id, 'apartments'],
        }),
    }),
  };
};
