import { Unit, UnitRequest, reviveUnit, serializeUnit } from '../types/unit';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useIntl } from 'react-intl';
import { useIdentity } from '../providers/IdentityProvider';

export const useUnits = (): UseQueryResult<Unit[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();
  const query = useQuery({
    queryKey: ['units', id],
    queryFn: async ({ queryKey: [, id] }) =>
      await apiClient.public.devices
        .getUnits(id ?? '')
        .then((units) => units.map((unit) => reviveUnit(unit))),
    enabled: true,
  });

  return query;
};

interface UseUnitMutations {
  create: UseMutationResult<Unit, Error, UnitRequest>;
  update: UseMutationResult<Unit, Error, Pick<Unit, 'id'> & UnitRequest>;
  remove: UseMutationResult<Unit, Error, Pick<Unit, 'id'>>;
}

export const useUnitMutations = (): UseUnitMutations => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return {
    create: useMutation<Unit, Error, UnitRequest, unknown>({
      mutationFn: (unit) =>
        apiClient.units
          .create(serializeUnit(unit))
          .then(reviveUnit)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte skapa lägenhet',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['units', id],
        }),
    }),
    update: useMutation<Unit, Error, Pick<Unit, 'id'> & UnitRequest>({
      mutationFn: ({ id, ...unit }) =>
        apiClient.units
          .update(id, serializeUnit(unit))
          .then(reviveUnit)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte uppdatera lägenhet',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['units', id],
        }),
    }),
    remove: useMutation<Unit, Error, Pick<Unit, 'id'>, unknown>({
      mutationFn: ({ id }) =>
        apiClient.units
          .delete(id)
          .then(reviveUnit)
          .catch((e) => {
            e.message = intl.formatMessage({
              defaultMessage: 'Kunde inte ta bort lägenhet',
            });
            throw e;
          }),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['units', id],
        }),
    }),
  };
};
