import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader } from '../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import { IconMap } from '@allbin/icons';
import { CompanyTenant, Unit } from '../types/unit';
import { Floor } from '../types/floor';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CompanyModal } from '../components/CompanyModal';
import { Company } from '../types/company';
import { useUnits } from '../hooks/useUnits';
import { useFloors } from '../hooks/useFloors';
import { useUrlQuery } from '../hooks/useUrlQuery';
import useFloorPlanMap from '../components/FloorPlan/useFloorPlanMap';
import { ServiceModal } from '../components/ServiceModal';
import { Service } from '../types/service';
import { useServices } from '../hooks/useServices';
import { Feature } from 'geojson';

interface Props {
  portrait?: boolean;
}
const FloorPlanView: FC<Props> = ({ portrait }) => {
  const query = useUrlQuery();
  const { data: units } = useUnits();
  const { data: floors } = useFloors();
  const { data: services } = useServices();

  const [selectedFloor, setSelectedFloor] = useState<Floor | undefined>(
    undefined,
  );
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>(undefined);
  const [selectedService, setSelectedService] = useState<Service | undefined>(
    undefined,
  );
  const [showModal, setShowModal] = useState<'company' | 'service' | ''>('');
  const [highlightUnit, setHighlightUnit] = useState('');

  const sortedFloors = useMemo(
    () => (floors ? floors.slice().sort((a, b) => a.level - b.level) : []),
    [floors],
  );

  useEffect(() => {
    if (!units) {
      return;
    }

    if (sortedFloors.length === 0) {
      return;
    }

    const unitId = query.get('unitId');
    if (!unitId) {
      setSelectedFloor(sortedFloors[0]);
      return;
    }

    const unit = units.find((u) => u.id === unitId);
    const floorIndex = sortedFloors.findIndex((f) => f.id === unit?.floor_id);
    setSelectedFloor(sortedFloors[floorIndex]);
    setHighlightUnit(unit?.id ?? '');
  }, [sortedFloors, query, units]);

  const onFeatureClick = useCallback(
    (feature?: Feature): void => {
      if (!services || !units) {
        return;
      }
      if (feature?.properties?.service_id) {
        const service = services.find(
          (s) => s.id === feature?.properties?.service_id,
        );
        if (!service) {
          return;
        }
        setSelectedService(service);
        setShowModal('service');
      } else if (feature?.properties?.unit_id) {
        const unit = units.find((u) => u.id === feature?.properties?.unit_id);
        setSelectedUnit(unit);
        setShowModal('company');
      }
    },
    [services, units],
  );

  const onFloorChange = useCallback((floor: Floor) => {
    setSelectedFloor(floor);
    setSelectedUnit(undefined);
    setSelectedService(undefined);
    setHighlightUnit('');
  }, []);

  useFloorPlanMap(highlightUnit, onFeatureClick, selectedFloor);

  const selectedCompanies = useMemo(() => {
    if (!selectedUnit) {
      return [] as Company[];
    }

    const companies = selectedUnit.tenants.filter(
      (c): c is CompanyTenant => c.type === 'company',
    );

    return companies.map((c) => c.company as Company);
  }, [selectedUnit]);

  if (!floors || !units) {
    return null;
  }

  return (
    <main className="flex h-full w-full overflow-hidden">
      <div className="flex flex-grow flex-col overflow-hidden">
        <PageHeader
          icon={<IconMap />}
          title={<FormattedMessage defaultMessage="Planskiss" />}
          slim={portrait}
        />
        <div className="flex min-h-0 w-full flex-grow flex-col">
          <div id="leafletmap" className="z-0 h-full w-full" />
        </div>
        {floors.length > 1 ? (
          <div className="flex w-full flex-col p-2">
            <p className="font-semibold">
              <FormattedMessage defaultMessage="Våning" />
            </p>
            <div className="flex h-16 items-center gap-1 overflow-x-auto">
              {sortedFloors.map((floor) => {
                const selected = floor === selectedFloor;
                return (
                  <button
                    key={floor.id}
                    className={`h-full w-max rounded px-7  ${
                      selected
                        ? 'bg-gray-500 text-white'
                        : 'bg-gray-200 active:bg-gray-100'
                    }`}
                    onClick={() => onFloorChange(floor)}
                  >
                    <p className="px-2 text-xl font-bold">
                      {floor.level_label}
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <CompanyModal
        show={showModal === 'company'}
        companies={selectedCompanies}
        onClose={() => setShowModal('')}
      />
      {showModal === 'service' ? (
        <ServiceModal
          show={true}
          service={selectedService}
          onClose={() => setShowModal('')}
        />
      ) : null}
    </main>
  );
};

export default FloorPlanView;
