import React, { FC } from 'react';
import { Dialog } from '@headlessui/react';
import { Service } from '../types/service';
import { FormattedMessage } from 'react-intl';
// import QRCode from 'qrcode';
import { prettifyTime } from '../utils/helpers';
import { Modal } from './Modal';
import { BookingEvent } from '../types/bookableResource';
import { useBookingResourcesWithEvents } from '../hooks/useBookingResourcesWithEvents';
import { IconGroup } from '@allbin/icons';

interface Props {
  service?: Service;
  show: boolean;
  onClose: () => void;
}

export const ServiceModal: FC<Props> = ({ service, show, onClose }) => {
  // const canvasRef = useRef<HTMLCanvasElement>(null);

  const { data: resources_by_service_id } = useBookingResourcesWithEvents();

  // useEffect(() => { // renable when we actually have qr links
  //   void (async () => {
  //     await sleep(1); // FIXME: wtf?
  //     if (!canvasRef.current || !service?.qr_link) {
  //       return;
  //     }
  //     QRCode.toCanvas(canvasRef.current, service?.qr_link || '', (err) => {
  //       if (err) {
  //         throw err;
  //       }
  //     });
  //   })();
  // }, [service?.qr_link]);

  if (!service) {
    return null;
  }

  const { name, photo, equipment, description } = service;

  const tag_resources = resources_by_service_id
    ? resources_by_service_id[service.id] || []
    : [];

  return (
    <Modal open={show} onClose={onClose} className="flex w-4/5">
      <div className="flex w-full flex-col gap-8">
        {tag_resources.map((resource) => {
          const service_resource = service.resources?.find((r) =>
            r.booking_ref
              ? r.booking_ref.connector_id === resource.connector_id &&
                r.booking_ref.source_id === resource.resource_source_id
              : false,
          );
          return (
            <>
              <div
                key={`${resource.connector_id}:${resource.resource_source_id}`}
                className="flex w-full flex-col gap-4"
              >
                {photo ? (
                  <div className="flex h-[24rem] min-h-[24rem] w-full">
                    <img
                      src={photo}
                      alt="service"
                      className="h-full w-full rounded bg-gray-200 object-cover"
                    />
                  </div>
                ) : null}
                <div className="flex justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-medium text-gray-900"
                  >
                    <span>{name}</span>
                  </Dialog.Title>
                  {service_resource && service_resource.capacity ? (
                    <span className="flex items-center gap-2 text-3xl text-gray-600">
                      <IconGroup className="h-7 w-7" />{' '}
                      {service_resource.capacity}{' '}
                      <FormattedMessage defaultMessage="platser" />
                    </span>
                  ) : null}
                </div>
                <div className="flex h-full w-full justify-between gap-6">
                  <div className="flex h-full flex-col gap-6">
                    {equipment.length > 0 ? (
                      <div className="flex flex-col gap-2">
                        <p className="text-lg font-bold">
                          <FormattedMessage defaultMessage="Utrustning" />
                        </p>
                        {equipment.map((e) => (
                          <p key={e}>{e}</p>
                        ))}
                      </div>
                    ) : null}
                    {description ? (
                      <div>
                        <p className="text-lg font-bold">
                          <FormattedMessage defaultMessage="Beskrivning" />
                        </p>
                        <p className="whitespace-pre-wrap">{description}</p>
                      </div>
                    ) : null}
                    {/*qr_link ? (
              <div className="mt-auto w-min">
                <p className="w-full text-center">
                  <FormattedMessage defaultMessage="Skanna för bokning" />
                </p>
                <canvas ref={canvasRef} />
              </div>
            ) : null*/}
                  </div>
                </div>
              </div>
              {service_resource && resource.events.length > 0 ? (
                <div className="ml-10 flex grow flex-col">
                  <p className="mb-3 text-lg font-bold">
                    <FormattedMessage defaultMessage="Bokningar" />
                  </p>
                  <EventsTimeline events={resource.events} />
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    </Modal>
  );
};

interface EventsTimelineProps {
  events: BookingEvent[];
}
const EventsTimeline: FC<EventsTimelineProps> = ({ events }) => {
  const rangeStart = 7;
  const rangeEnd = 18;
  const rangeHours = rangeEnd - rangeStart;
  const now = new Date();
  const nowTop = toPercent(toDecimal(now, rangeStart, rangeEnd), rangeHours);

  return (
    <div className="relative mr-10 flex w-20 grow flex-col justify-between">
      {Array.from({ length: rangeHours + 1 }, (_, i) => i + rangeStart).map(
        (hour) => (
          <div key={hour} className="relative w-full ">
            <p className="absolute -right-2 -translate-y-1/2 translate-x-full">
              {hour}:00
            </p>
            <div className="h-[2px] w-full bg-gray-300" />
          </div>
        ),
      )}
      {events.map(
        ({
          contact_primary,
          contact_secondary,
          contact_tertiary,
          from,
          to,
        }) => {
          const start = new Date(from);
          const end = new Date(to);
          const startNumber = toDecimal(start, rangeStart, rangeEnd);
          const endNumber = toDecimal(end, rangeStart, rangeEnd);
          const top = toPercent(startNumber, rangeHours);
          const height = toPercent(endNumber - startNumber, rangeHours);

          let bgColor = 'bg-red-300';
          let color = 'text-gray-800';
          if (now > end) {
            bgColor = 'bg-gray-100';
            color = 'text-gray-400';
          } else if (now > start) {
            bgColor = 'bg-red-500';
          }

          return (
            <div
              key={from}
              className={`absolute w-full px-[5px] py-[1px]`}
              style={{ top: `${top}%`, height: `${height}%` }}
            >
              <div
                className={`h-full w-full rounded ${bgColor} ${color} overflow-hidden px-1 py-[1px]`}
              >
                <TextLine text={contact_primary} />
                <TextLine text={contact_secondary} />
                <TextLine text={contact_tertiary} />
              </div>
            </div>
          );
        },
      )}
      {nowTop > 0 && nowTop < 100 ? (
        <div
          className="absolute h-[2px] w-full -translate-y-[1px] bg-blue"
          style={{ top: `${nowTop}%` }}
        >
          <p className="absolute left-full top-[1px] -translate-y-1/2 rounded bg-blue px-1 text-white">
            {prettifyTime(now)}
          </p>
        </div>
      ) : null}
    </div>
  );
};

interface TextLineProps {
  text?: string;
}
const TextLine: FC<TextLineProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <p className="min-h-max overflow-hidden text-ellipsis whitespace-nowrap text-xs leading-3">
      {text}
    </p>
  );
};

const toDecimal = (date: Date, rangeStart: number, rangeEnd: number): number =>
  Math.min(Math.max(date.getHours(), rangeStart), rangeEnd) -
  rangeStart +
  date.getMinutes() / 60;

const toPercent = (value: number, rangeLength: number): number =>
  (value / rangeLength) * 100;
